/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-danger */
import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
// import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Lead from '../components/Common/Lead';
import Services from '../components/Common/Services';

const Section = styled.section`
  h2 {
    padding-bottom: 0;

    span {
      display: block;
    }
  }
`;

const MsgAlert = styled.p`
  background: #303030;
  border-left: 4px solid #0aa6ff;
  box-shadow: 2px 2px 5px -3px rgba(0,0,0,1);
  border-radius: 8px;
  margin: 9rem 0 5rem;
  max-width: 600px;
  overflow: hidden;
  padding: 16px 8px 16px 24px;
  text-align: left;
`;

const siteMetadata = {
  title: 'Wycena starych monet i banknotów - Monety Olsztyn',
  description: 'W naszym sklepie numizmatycznym bezpłatnie wycenisz stare monety, banknoty i falerystykę. Chętnie skupujemy rzadkie monety. Skorzystaj z naszej wiedzy.',
  siteName: 'Monety Olsztyn',
  imageUrl: 'https://monety.olsztyn.pl/favicons/splash-screen.png',
  siteUrl: 'https://monety.olsztyn.pl/wycena-monet-i-banknotow/',
  locale: 'pl_PL',
};

const ValuationPage = ({ data }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'pl' }}>
      <title>{siteMetadata.title}</title>
      <meta name="description" content={siteMetadata.description} />
      <meta property="og:locale" content={siteMetadata.locale} />
      <meta property="og:site_name" content={siteMetadata.siteName} />
      <meta property="og:image" content={siteMetadata.imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteMetadata.siteUrl} />
      <meta property="og:title" content={siteMetadata.title} />
      <meta property="og:description" content={siteMetadata.description} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={siteMetadata.title} />
      <meta name="twitter:description" content={siteMetadata.description} />
      <meta name="twitter:image" content={siteMetadata.imageUrl} />

      <link rel="canonical" href={siteMetadata.siteUrl} />
    </Helmet>

    <Section className="section-text section-text--no-border">
      <h1>
        <span>Posiadasz stare monety lub banknoty?</span>
        <span>Dowiedz się, ile są warte!</span>
      </h1>

      <Lead
        desc="Jeśli posiadasz stare banknoty, monety lub odznaczenia wojskowe i nie wiesz, jaką mają wartość, chętnie Ci pomożemy. Specjalizujemy się w wycenie polskich banknotów i monet, bazując na naszym wieloletnim doświadczeniu."
      />

      <p>Gwarantujemy dokładną wycenę opartą na aktualnych cenach rynkowych, co zapewnia uczciwą i&nbsp;rzetelną ocenę Twoich kolekcjonerskich przedmiotów.</p>

      <figure>
        <GatsbyImage
          image={getImage(data.wycena_monet)}
          alt="Wycena starych monet historycznych"
        />
        <figcaption>Wyceniamy stare monety, banknoty, falerystykę</figcaption>
      </figure>

      <h2>
        <span>Wybierz najwygodniejszy</span>
        <span>sposób wyceny numizmatów</span>
      </h2>

      <ol className="list-decimal">
        <li>
          <h3><Link to="/kontakt/">Wycena e-mail</Link></h3>
          <p>Tę formę wyceny preferujemy najbardziej. Wystarczy, że zrobisz grupowe zdjęcia swoich monet lub banknotów i wyślesz je na adres <a href="mailto:kontakt@monety.olsztyn.pl">kontakt@monety.olsztyn.pl</a> lub przez aplikację <strong>WhatsApp</strong>.</p>
          <p>Dla monet potrzebujemy zdjęć obu stron, a w przypadku banknotów ważne jest, aby widoczna była data emisji i numer seryjny. Wycenę na podstawie zdjęć przeprowadzamy zazwyczaj w ciągu <strong>1 dnia roboczego</strong>.</p>
        </li>

        <li>
          <h3><Link to="/kontakt/">Wycena telefoniczna</Link></h3>
          <p style={{ margin: '0' }}>Oferujemy również wstępną wycenę przez telefon, która pozwala zorientować się w wartości kolekcji.</p>
          <p>Zadzwoń do nas: <a href="tel:507506476">507 506 476</a></p>
        </li>

        <li>
          <h3>Wycena bezpośrednia</h3>
          <p>Odbywa się u klienta lub podczas wcześniejszego umówienia się na spotkanie.</p>
        </li>

        <li>
          <h3>Wycena samodzielna</h3>
          <p>Wartość monet lub banknotów można samemu oszacować, korzystając z archiwów typu Onebid lub Allegro. Warto zwrócić uwagę, aby licytacje były zakończone sprzedażą.
          </p>
        </li>
      </ol>

      <MsgAlert>
        <strong>Bardzo ważne!!!</strong> prosimy nie czyścić, nie myć, nie polerować monet. Naturalna&nbsp;patyna, która pojawia się na starszych monetach, może podnieść jej&nbsp;wartość. Monety czyszczone lub polerowane znacznie obniżą jej&nbsp;cenę.
      </MsgAlert>

      <h2>
        <span>Dlaczego warto skorzystać</span>
        <span>z naszych usług?</span>
      </h2>

      <h3>Darmowa wycena</h3>
      <p>Dbamy o Twój komfort i zadowolenie, dlatego oferujemy bezpłatną wycenę Twoich numizmatów.</p>

      <h3>Dojazd do Klienta</h3>
      <p>Aby ułatwić proces wyceny, możemy dojechać do Ciebie w dogodnym terminie. Oszczędź czas i energię, a my zajmiemy się resztą.</p>

      <p>Nie trać czasu na domysły. Skorzystaj z naszych usług i poznaj prawdziwą wartość swoich numizmatów już dziś!</p>
    </Section>

    <Services />
  </>
);

export const query = graphql`
  query {
    wycena_monet: file(relativePath: {regex: "/wycena-monet.jpeg/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
          width: 900
        )
      }
    }
  }
`;

export default ValuationPage;
